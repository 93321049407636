<!--  -->
<template>
  <div class="stu">
    <el-card class="box-card">
      <div>共计老师{{ total }}名</div>

      <div class="table" style="margin-top: 20px" v-loading="loading">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          :row-key="(row) => row.id"
          @sort-change="sortChange"
        >
          <el-table-column label="学校/单位-姓名" width="200" align="center" sortable="custom">
            <template slot-scope="scope">
              <span class="ProblemList-name">{{
               scope.row.show_name
              }}</span></template
            >
          </el-table-column>
          <!-- <el-table-column label="学校/单位" prop="school_name" width="180"  align="center" sortable="custom">
            <template slot-scope="scope">
              <span>{{ scope.row.school_work }}</span>
            </template>
          </el-table-column> -->
          <el-table-column label="教案数量" prop="plan_num" align="center" sortable="custom">
            <template slot-scope="scope">{{ scope.row.plan_num }}</template>
          </el-table-column>
          <el-table-column label="课程数量" prop="course_num" align="center" sortable="custom">
            <template slot-scope="scope">{{ scope.row.course_num }}</template>
          </el-table-column>
          <el-table-column label="当前开班数量" prop="course_open_num" width="130" align="center" sortable="custom">
            <template slot-scope="scope">{{ scope.row.course_open_num }}</template>
          </el-table-column>
          <el-table-column label="当前学生数量" prop="student_num" width="130" align="center"  sortable="custom">
            <template slot-scope="scope">{{ scope.row.student_num }}</template>
          </el-table-column>
          <el-table-column label="累计开班数量" prop="course_his_num" width="130" align="center" sortable="custom">
            <template slot-scope="scope">{{ scope.row.course_his_num }}</template>
          </el-table-column>
          <el-table-column label="累计学生数量" prop="student_his_num" width="130" align="center" sortable="custom">
            <template slot-scope="scope">{{ scope.row.student_his_num }}</template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="130" fixed="right">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                >查看班课列表</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination" style="margin-top: 20px">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="currentChange"
          @size-change="handleSizeChange"

          :page-size="pageSize"
          :current-page="page"
        >
        </el-pagination>
      </div>
    </el-card>

    <el-dialog
      title="查看班课列表"
      width="30%"
      :visible.sync="dialogTableVisible"
    >
      <el-table :data="gridData">
        <el-table-column
          property="course_name"
          label="班课名称"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              @click="handleClickDetails(scope.row)"
              type="text"
              size="small"
              >查看班课详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
  
  <script>
export default {
  props: {
    timevalue: {
      type: Array,
      default: ()=>[],
    },
    template_id: {
      type: Number,
      default: 0,
    },
  },
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      loading: false,
      dialogTableVisible: false,
      page: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      gridData: [],
      sort:"",
      start_time:"",
      end_time:"",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    timevalue:{
      handler(value) {
        console.log(value,"value")
        if(value){
          this.start_time=value[0]
          this.end_time=value[1]
        }else{
          this.start_time=""
          this.end_time=""
        }
        this.getTeacherList();
      }
    },
    template_id:{
      handler(value) {
        console.log(value,"value")
        if(value){
          this.template_id=value
        }else{
          this.template_id=""
        }
        this.getTeacherList();
      }
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    console.log(this.timevalue, "timevalue");
    if (this.timevalue && this.timevalue.length>0) {
      this.start_time = this.timevalue[0];
      this.end_time = this.timevalue[1];
    } else {
      this.start_time = "";
      this.end_time = "";
    }
    this.getTeacherList();
  },
  //方法集合
  methods: {
    // 获取老师列表
    getTeacherList() {
      // page: 1,
      // pageSize: 50,
      // total: 0,
     this.loading=true

      this.$http.get(
          `/xapi/user.group/teachStatisticList?page=` +
            this.page +
            "&page_size=" +
            this.pageSize
            +'&sort='+this.sort
            +"&template_id=" + Number(this.template_id)
            +"&start_time=" +this.start_time
            +"&end_time=" + this.end_time

        )
        .then((res) => {
          console.log(res, "liebbbbb");
           this.loading=false
          this.tableData = res.data.data;
          console.log(this.tableData,"this.tableData")
          this.total = res.data.total;
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },
    sortChange(column){
      console.log(column,"column")
      const orderData=column.order == "descending" ? "desc" : column.order == "ascending" ? "asc" : null
      console.log(orderData,"orderData")
      this.sort=column.prop+'-'+orderData
      this.getTeacherList()
    },
    handleClick(row) {
      console.log(row);
      this.gridData=row.course_list
      this.dialogTableVisible = true;
    },
    // 查看班课i详情
    handleClickDetails(row) {
      console.log("详情");
      this.$handleRoute({ id: row.id }, "/teaching/totalDetail", "_blank");
    },
    // 分页
    currentChange(e) {
      this.page = e;
        this.getTeacherList();
    },
    handleSizeChange(e){
      this.pageSize = e;
        this.getTeacherList();
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
  <style  lang='less' scoped>
.stu {
  .box-card {
    margin-top: 20px;
    ::v-deep {
      .border-base {
        text-align: center;
        border-radius: 20px;
        padding: 10px;
        border: 1px solid #ccc;
      }
    }
    .pagination {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>