<!--  -->
<template>
  <div class="">
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <el-button-group>
        <el-button
          type="primary"
          :plain="activeName !== 'stu'"
          @click="handleTabClick('stu')"
          >学生</el-button
        >
        <el-button
          type="primary"
          :plain="activeName !== 'situation'"
          @click="handleTabClick('situation')"
          >学情</el-button
        >
        <el-button
          type="primary"
          :plain="activeName !== 'tea'"
          @click="handleTabClick('tea')"
          >老师</el-button
        >
        <el-button
          type="primary"
          :plain="activeName !== 'now'"
          @click="handleTabClick('now')"
          >班课</el-button
        >
        <!-- <el-button
          type="primary"
          :plain="activeName !== 'history'"
          @click="handleTabClick('history')"
          >班课(历史)</el-button
        > -->
      </el-button-group>
      <div style="display: flex; align-items: center; margin-left: 30px">
        <div class="team">统计模板选择</div>
        <el-select
          clearable
          v-loading="teamDataLoading"
          v-model="value"
          placeholder="请选择"
          @change="changeTemplate"
        >
          <el-option
            v-for="item in templateData"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="display: flex; align-items: center">
        <div
          style="
            margin-right: 10px;
            font-weight: 600;
            font-size: 14px;
            line-height: 30px;
          "
        >
          统计时间范围:
        </div>
        <el-date-picker
          v-model="timevalue"
          type="daterange"
          @change="changeTime"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
        <el-button style="margin-left: 10px" @click="nowTime" type="primary" size="mini">此刻</el-button>
      </div>
    </div>

    <div>
      <stu v-if="activeName == 'stu'" :timevalue="timevalue" :template_id="template_id"></stu>
      <stuSituation v-if="activeName == 'situation'" :timevalue="timevalue" :template_id="template_id"></stuSituation>
      <teacherList v-if="activeName == 'tea'" :timevalue="timevalue" :template_id="template_id"></teacherList>
      <classNow v-if="activeName == 'now'" :timevalue="timevalue" :template_id="template_id"></classNow>
      <classHistory v-if="activeName == 'history'" :timevalue="timevalue" :template_id="template_id"></classHistory>
    </div>
    <!-- <div style="font-size: 12px; color: gray;margin-top: 10px;">
      <p>提交作业率 = 提交作业次数/作业次数</p>
      <p>提交题目率 = 提交题目数/作业内总题目数</p>
      <p>编程题目全AC率 = 提交结果全AC题目数/作业内编程题目总数</p>
      <p>一次提交结果全AC率 = 一次提交结果全AC题目数/提交编程题目总数</p>

      <p>
        排名得分 =
        出勤率*0.2+提交题目率*0.1+(编程题目全AC率)*0.2+(Σ考试成绩/Σ考试满分)*0.5
      </p>

      <p>注:AC的含义是ACCEPT,即正确</p>
    </div> -->
  </div>
</template>

<script>
import stu from "./c/stu.vue";
import stuSituation from "./c/stuSituation.vue";
import teacherList from "./c/teacherList.vue";
import classNow from "./c/classNow.vue";
import classHistory from "./c/classHistory.vue";
import dayjs from "dayjs";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    stu,
    stuSituation,
    teacherList,
    teacherList,
    classNow,
    classHistory,
  },
  data() {
    //这里存放数据
    return {
      activeName: "first",
      tabPosition: "1",
      activeName: "stu",
      value: "",
      timevalue: [],
      teamDataLoading: false,
      templateData: [],
      start_time:"",
      end_time:"",
      template_id:0, //模版ID
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getTemplateList()
  },
  //方法集合
  methods: {
    // 此刻
    nowTime(){
      this.start_time=dayjs().format("YYYY-MM-DD")
      this.end_time=dayjs().format("YYYY-MM-DD")
      this.timevalue=[this.start_time,this.end_time]
      // console.log(this.timevalue,"this.timevalue")
    },
    changeTemplate(e){
      this.template_id=e
      console.log(this.template_id,"sd")
    },
    // 获取模板列表
    getTemplateList() {
      this.teamDataLoading = true;
      this.$http
        .post("/xapi/user.group/teamFocusTemplateList", {
          group_id: this.groupId,
          page: 1,
          page_size: 99,
        })
        .then((res) => {
          this.teamDataLoading = false;
          this.templateData = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleTabClick(tab) {
      this.activeName = tab;
    },
    // 时间选择
    changeTime(e){
      console.log(e,"eeeeee")
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style  lang='less' scoped>
.team {
  min-width: 60px;
  margin-right: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
}
</style>