<!--  -->
<template>
  <div class="stu">
    <el-card class="box-card">
      <div class="table" style="margin-top: 20px" v-loading="loading">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @row-click="rowDblclick"
          :row-key="(row) => row.id"
        >
          <el-table-column label="序号" type="index" width="50" align="center">
          </el-table-column>
          <el-table-column label="班课名称" align="center" width="220">
            <template slot-scope="scope">
              <span>{{ scope.row.course_name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="学校-创建人" align="center">
            <template slot-scope="scope">{{ scope.row.show_name }} </template>
          </el-table-column>
          <el-table-column label="开班时间" align="center">
            <template slot-scope="scope">{{
              scope.row.course_start_date
            }}</template>
          </el-table-column>
          <el-table-column label="学习进展%" align="center">
            <template slot-scope="scope">{{
              scope.row.student_progress
            }}</template>
          </el-table-column>
          <el-table-column label="报名学生人数" align="center">
            <template slot-scope="scope">{{
              scope.row.apply_student_num
            }}</template>
          </el-table-column>
          <el-table-column label="实际上课学生人数" align="center">
            <template slot-scope="scope">{{ scope.row.student_num }}</template>
          </el-table-column>
          <el-table-column label="已布置作业次数" align="center">
            <template slot-scope="scope">{{ scope.row.task_num }}</template>
          </el-table-column>
          <el-table-column label="计划结班时间" align="center">
            <template slot-scope="scope">{{
              scope.row.course_end_date
            }}</template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination" style="margin-top: 20px">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          :total="total"
          @current-change="currentChange"
          @size-change="handleSizeChange"
          :page-size="pageSize"
          :current-page="page"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
  
  <script>
export default {
  props: {
    timevalue: {
      type: Array,
      default: () => [],
    },
    template_id: {
      type: Number,
      default: 0,
    },
  },
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      loading: false,
      page: 1,
      pageSize: 50,
      total: 0,
      tableData: [],
      start_time: "",
      end_time: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    timevalue: {
      handler(value) {
        console.log(value, "value");
        if (value) {
          this.start_time = value[0];
          this.end_time = value[1];
        } else {
          this.start_time = "";
          this.end_time = "";
        }
        this.getClassNowList();
      },
    },
    template_id: {
      handler(value) {
        console.log(value, "value");
        if (value) {
          this.template_id = value;
        } else {
          this.template_id = "";
        }
        this.getClassNowList();
      },
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    console.log(this.timevalue, "timevalue");
    if (this.timevalue && this.timevalue.length>0) {
      this.start_time = this.timevalue[0];
      this.end_time = this.timevalue[1];
    } else {
      this.start_time = "";
      this.end_time = "";
    }
    this.getClassNowList();
  },
  //方法集合
  methods: {
    // 获取当前班课列表
    getClassNowList() {
      // page: 1,
      // pageSize: 50,
      // total: 0,
      this.loading = true;

      this.$http
        .get(
          `/xapi/user.group/courseStaticticNowList?page=` +
            this.page +
            "&page_size=" +
            this.pageSize +
            "&learn_mode=2"+
            "&template_id=" +
            Number(this.template_id) +
            "&start_time=" +
            this.start_time +
            "&end_time=" +
            this.end_time
        )
        .then((res) => {
          this.loading = false;

          this.tableData = res.data.data;
          this.total = res.data.total;
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },
    // 分页
    currentChange(e) {
      this.page = e;
      this.getClassNowList();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getClassNowList();
    },
    // 点击详情
    rowDblclick(row) {
      console.log(row, "orwss");
      // return
      this.$handleRoute({ id: row.id }, "/teaching/totalDetail", "_blank");

      //   console.log(row, "rwsssssss");
      //   const resolved = this.$router.resolve({ path: '/views/api/teaching/questOther/totalDetail', query: { id: 1 } });
      //   console.log(resolved, "resolvedresolved");
      //   window.open(resolved.href, '_blank');
      //   resolved.then((route) => {
      //     window.open(route.href, '_blank');
      //   });
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
  <style  lang='less' scoped>
.stu {
  .box-card {
    margin-top: 20px;
    ::v-deep {
      .border-base {
        text-align: center;
        border-radius: 20px;
        padding: 10px;
        border: 1px solid #ccc;
      }
      .el-table__row {
        cursor: pointer !important;
      }
    }
    .pagination {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>